import { AppBar, formatDate, getDayOfWeek } from "common";
import { StyledButton } from "common/common.styled-button";
import { useNavigate } from "react-router-dom";

import styles from "./landing.page.module.scss";

export function LandingPage(): JSX.Element {
  return (
    <>
      <AppBar bigLogo />
      <LandingPageContent />
    </>
  );
}

function LandingPageContent(): JSX.Element {
  const today = new Date();
  const dateString = formatDate(today);
  const dayOfWeekString = getDayOfWeek(today);

  const navigate = useNavigate();
  return (
    <main className={styles.container}>
      <div>
        <p className={styles.dateParagraph}>
          <time>
            <span className={styles.dateString}>{dateString}</span>{" "}
            {dayOfWeekString}
          </time>
        </p>
        <p className={styles.desc}>
          <span className={styles.hightlight}>
            一個人故事是微不足道，但一群人的故事就是「文化」。當中包括一個社區的語言，
            歷史，環境，習俗，藝術等。港常識是一個關於香港文化挑戰，透過每日8問，
            讓大家挑戰一下自己了解香港的程度，從新認識「我們」的文化。
          </span>
        </p>
        <div>
          <StyledButton
            tabIndex={0}
            className={styles.secondaryButton}
            onClick={() => {
              navigate("/rules");
            }}
          >
            規則
          </StyledButton>
          <StyledButton tabIndex={0} className={styles.secondaryButton}>
            作答記錄
          </StyledButton>
        </div>
      </div>

      {/* <h1 className={styles.gameRuleHeader}>遊戲規則</h1>
      <ul>
        <li>每日只有8條問題</li>
        <li>一日只可以遊玩一次，不可重復回答</li>
        <li>每題玩家需要在限時20秒內作答</li>
        <li>不作答視為略過，無任何分數</li>
        <li>不要告訴他人答案，請保持公德心</li>
        <li>不要作弊，保持遊戲挑戰性</li>
        <li>不要對外公開問題與答案，保持神秘</li>
      </ul> */}
      <div className={styles.actionsContainer}>
        <div className={styles.actionRow}>
          <StyledButton
            tabIndex={0}
            underLineColor="var(--primary-color)"
            className={styles.playButton}
            align="right"
            onClick={() => {
              navigate("/game");
            }}
          >
            開始是日挑戰
          </StyledButton>
        </div>
      </div>
    </main>
  );
}
