import { AppBar } from "common";
import { StyledButton } from "common/common.styled-button";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import styles from "./rules.page.module.scss";

export function RulesPage(): JSX.Element {
  return (
    <>
      <AppBar bigLogo />
      <RulesPageContent />
    </>
  );
}

function RulesPageContent(): JSX.Element {
  const navigate = useNavigate();

  const listVariants = {
    show: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const ruleItemVariants = {
    hidden: {
      opacity: 0,
      x: "-1rem",
    },
    show: {
      transition: {
        type: "tween",
        easings: "easeOut",
        duration: 0.5,
      },
      opacity: 1,
      x: "0rem",
    },
  };

  return (
    <main className={styles.container}>
      <h1 className={styles.gameRuleHeader}>遊戲規則</h1>
      <motion.ul
        variants={listVariants}
        initial="hidden"
        animate="show"
        className={styles.ruleList}
      >
        <motion.li variants={ruleItemVariants}>
          <span>1. 每日只有8條問題</span>
        </motion.li>
        <motion.li variants={ruleItemVariants}>
          <span>2. 一日只可以遊玩一次，不可重復回答</span>
        </motion.li>
        <motion.li variants={ruleItemVariants}>
          <span>3. 每題玩家需要在限時20秒內作答</span>
        </motion.li>
        <motion.li variants={ruleItemVariants}>
          <span>4. 不作答視為略過，無任何分數</span>
        </motion.li>
        <motion.li variants={ruleItemVariants}>
          <span>5. 不要告訴他人答案，請保持公德心</span>
        </motion.li>
        <motion.li variants={ruleItemVariants}>
          <span>6. 不要作弊，保持遊戲挑戰性</span>
        </motion.li>
        <motion.li variants={ruleItemVariants}>
          <span>7. 不要對外公開問題與答案，保持神秘</span>
        </motion.li>
      </motion.ul>
      <div className={styles.actionsContainer}>
        <div className={styles.actionRow}>
          <StyledButton
            tabIndex={0}
            className={styles.backButton}
            align="left"
            onClick={() => {
              navigate("/");
            }}
          >
            回到主頁
          </StyledButton>
        </div>
      </div>
    </main>
  );
}
