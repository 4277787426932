import { cloneDeep } from "common";

export type Question = {
  questionText: string;
  answerTexts: string[];
  correctAnswerIndex: number;
  submittedAnswerIndex?: number | null;
};

export type Quiz = {
  questions: Question[];
  currentQuestionIndex: number;
  createdAt: Date;
};

export function isCorrect(question: Question): boolean {
  return question.correctAnswerIndex === question.submittedAnswerIndex;
}

export function isCompleted(quiz: Quiz): boolean {
  return quiz.questions.every(
    (question) => question.submittedAnswerIndex !== undefined
  );
}

export function getCurrentQuestion(quiz: Quiz): Question {
  return quiz.questions[quiz.currentQuestionIndex];
}

export function answerCurrentQuestion(
  quiz: Quiz,
  answerIndex: number | null
): Quiz {
  const quizClone = cloneDeep(quiz);
  getCurrentQuestion(quizClone).submittedAnswerIndex = answerIndex;
  quizClone.currentQuestionIndex++;
  return quizClone;
}

export function getScore(quiz: Quiz): number {
  return quiz.questions.filter(isCorrect).length;
}

export function getMaxScore(quiz: Quiz): number {
  return quiz.questions.length;
}
