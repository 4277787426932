import { logger } from "logging";
import { getMaxScore, getScore, Quiz } from "./quiz.entity";

export function exportQuizResultImage(quiz: Quiz): Promise<File> {
  logger.info("Exporting result image for quiz");
  const canvas = renderCanvas(quiz);
  return exportCanvasImage(canvas);
}

// TODO
function renderCanvas(quiz: Quiz): HTMLCanvasElement {
  const canvas = document.createElement("canvas");
  canvas.width = 500;
  canvas.height = 500;
  const canvasContext = canvas.getContext("2d");
  if (!canvasContext) {
    return canvas;
  }
  canvasContext.fillStyle = "white";
  canvasContext.fillRect(0, 0, 500, 500);
  const scoreLabel = `${getScore(quiz)} / ${getMaxScore(quiz)}`;
  canvasContext.fillStyle = "black";
  canvasContext.fillText(scoreLabel, 100, 100, 300);
  return canvas;
}

async function exportCanvasImage(canvas: HTMLCanvasElement): Promise<File> {
  const url = canvas.toDataURL();
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], "kong-trivia-result.png", {
    type: "image/png",
    lastModified: new Date().getTime(),
  });
}
