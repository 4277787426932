import { getPreviousHkMidnight, getYesterday, isDateEqual } from "common";
import { logger } from "logging";
import { Quiz } from "./quiz.entity";
import { fetchAllQuizzes } from "./quiz.store";

export async function getQuizStreak(): Promise<number> {
  logger.info("Getting quiz streak");
  const quizzes = await fetchAllQuizzes();

  let streak = 0;
  let date = new Date();
  let quizCreatedOnDate: Quiz | undefined;
  do {
    quizCreatedOnDate = findQuizCreatedOnHkDay(quizzes, date);
    date = getYesterday(date);
    streak++;
  } while (quizCreatedOnDate);
  return streak - 1;
}

function findQuizCreatedOnHkDay(
  quizzes: Quiz[],
  createdAt: Date
): Quiz | undefined {
  return quizzes.find((quiz) =>
    isDateEqual(
      getPreviousHkMidnight(quiz.createdAt),
      getPreviousHkMidnight(createdAt)
    )
  );
}
