import { useCallback, useEffect, useState } from "react";
import { AppBar } from "common";
import { createQuiz } from "./quiz.create";
import { QuestionPrompt } from "./quiz.question-prompt";
import { QuizResultIndicator } from "./quiz.result-indicator";
import {
  Quiz,
  answerCurrentQuestion,
  getCurrentQuestion,
  isCompleted,
} from "./quiz.entity";
import { persistQuiz } from "./quiz.store";
import { getQuizStreak } from "./quiz.get-streak";
import { checkIfAlreadyPlayedQuiz } from "./quiz.check-if-already-played";
import { QuizPlayedAlreadyWarning } from "./quiz.played-already-warning";

export function QuizPage(): JSX.Element {
  return (
    <>
      <AppBar />
      <QuizPageContent />
    </>
  );
}

function QuizPageContent(): JSX.Element {
  const [quiz, setQuiz] = useState<Quiz>();
  const [hasAlreadyPlayed, setHasAlreadyPlayed] = useState<boolean>();
  const [streak, setStreak] = useState<number>();

  useEffect(() => {
    checkIfAlreadyPlayedQuiz().then(setHasAlreadyPlayed);
  }, []);

  useEffect(() => {
    if (hasAlreadyPlayed === false && !quiz) {
      createQuiz().then(setQuiz);
    }
  }, [hasAlreadyPlayed, quiz]);

  const onAnswer = useCallback(
    async (index: number | null) => {
      if (!quiz) {
        return;
      }
      const answeredQuiz = answerCurrentQuestion(quiz, index);
      setQuiz(answeredQuiz);
      if (isCompleted(answeredQuiz)) {
        await persistQuiz(quiz);
        const newStreak = await getQuizStreak();
        setStreak(newStreak);
      }
    },
    [quiz]
  );

  if (hasAlreadyPlayed) {
    return <QuizPlayedAlreadyWarning />;
  }

  if (!quiz) {
    return <>Loading...</>;
  }

  if (isCompleted(quiz)) {
    if (streak === undefined) {
      return <>Loading...</>;
    }
    return <QuizResultIndicator quiz={quiz} streak={streak} />;
  }

  const currentQuestion = getCurrentQuestion(quiz);

  return (
    <QuestionPrompt
      question={currentQuestion}
      questionIndex={quiz.currentQuestionIndex}
      onAnswer={onAnswer}
    />
  );
}
