import { getPreviousHkMidnight } from "common";
import { createInstance } from "indexed-db";
import { logger } from "logging";
import { Quiz } from "./quiz.entity";

const quizStore = createInstance({ name: "quiz" });

export async function persistQuiz(quiz: Quiz): Promise<Quiz> {
  const key = getQuizKey(quiz);
  logger.info(`Persisting quiz to IndexedDb with key ${key}`);
  const persistedQuiz = await quizStore.setItem(key, quiz);
  return persistedQuiz;
}

export async function fetchQuizCreatedOnHkDay(
  createdAt: Date
): Promise<Quiz | null> {
  const key = getQuizKeyByCreatedAt(createdAt);
  logger.info(`Fetching quiz from IndexedDb by key ${key}`);
  return quizStore.getItem<Quiz>(key);
}

export async function fetchAllQuizzes(): Promise<Quiz[]> {
  logger.info(`Fetching all quizzes from IndexedDb`);
  const keys = await quizStore.keys();
  const quizzes = await Promise.all(
    keys.map((key) => quizStore.getItem<Quiz>(key))
  );
  return quizzes.filter(isQuizNotNull);
}

function isQuizNotNull(quiz: Quiz | null): quiz is Quiz {
  return quiz !== null;
}

function getQuizKey(quiz: Quiz): string {
  return getQuizKeyByCreatedAt(quiz.createdAt);
}

function getQuizKeyByCreatedAt(createdAt: Date): string {
  return getPreviousHkMidnight(createdAt).toISOString();
}
