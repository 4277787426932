import { useEffect, useState } from "react";
import clsx from "clsx";
import { Icon } from "./common.icon";
import styles from "./common.timer-bar.module.scss";

export type TimerBarProps = {
  className?: string;
  totalMs: number;
  leftMs: number;
};

export function TimerBar({
  className,
  totalMs,
  leftMs,
}: TimerBarProps): JSX.Element {
  const leftSeconds = Math.ceil(leftMs / 1000);
  const percentage = 1 - leftMs / totalMs;
  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.timeLeftLabel}>
        <Icon name="time-left" className={styles.timeLeftIcon} size="1.2rem" />
        {leftSeconds}
      </div>
      <div className={styles.bar}>
        <div
          className={styles.progressIndicator}
          style={{ transform: `scaleX(${percentage})` }}
          data-percentage={percentage}
        />
      </div>
    </div>
  );
}

export type UseTimerProps = {
  totalMs: number;
  updateIntervalMs: number;
  isPaused?: boolean;
  onUpdate?: (leftMs: number) => void;
};

export function useTimer(
  { totalMs, updateIntervalMs, isPaused, onUpdate }: UseTimerProps,
  dependencies?: any[]
): number {
  const [leftMs, setLeftMs] = useState(totalMs);

  useEffect(() => {
    if (isPaused) {
      return () => undefined;
    }
    const updateLeftMs = () => {
      const decrementedLeftMs = leftMs - updateIntervalMs;
      const newLeftMs = decrementedLeftMs >= 0 ? decrementedLeftMs : 0;
      onUpdate?.(newLeftMs);
      setLeftMs(newLeftMs);
    };
    const intervalId = setInterval(updateLeftMs, updateIntervalMs);
    return () => clearInterval(intervalId);
  }, [updateIntervalMs, leftMs, isPaused, onUpdate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLeftMs(totalMs), [totalMs, ...(dependencies ?? [])]);

  return leftMs;
}
