import { useCallback, useEffect, useState } from "react";
import { Button, formatDate, getDayOfWeek, Icon } from "common";
import { getMaxScore, getScore, Quiz } from "./quiz.entity";
import { exportQuizResultImage } from "./quiz.export-result-image";
import styles from "./quiz.result-indicator.module.scss";

export type QuizResultIndicatorProps = {
  quiz: Quiz;
  streak: number;
};

export function QuizResultIndicator({
  quiz,
  streak,
}: QuizResultIndicatorProps): JSX.Element {
  const [resultImageFile, setResultImageFile] = useState<File>();

  useEffect(() => {
    exportQuizResultImage(quiz).then(setResultImageFile);
  }, [quiz]);

  const onClickShare = useCallback(() => {
    if (!resultImageFile) {
      return;
    }
    navigator.share({ files: [resultImageFile] });
  }, [resultImageFile]);

  return (
    <div className={styles.resultCanvas}>
      <DateLabel date={quiz.createdAt} />
      <StreakLabel streak={streak} />
      <ScoreLabel quiz={quiz} />
      <ResultGrid quiz={quiz} />
      <div className={styles.buttonList}>
        <Button className={styles.button} onClick={onClickShare}>
          分享結果
        </Button>
        <Button className={styles.button}>觀看結果</Button>
      </div>
    </div>
  );
}

type DateLabelProps = {
  date: Date;
};

function DateLabel({ date }: DateLabelProps): JSX.Element {
  const dateString = formatDate(date);
  const dayOfWeek = getDayOfWeek(date);
  return (
    <div className={styles.dateParagraph}>
      <time>
        <span className={styles.timeDate}>{dateString}</span> {dayOfWeek}
      </time>
    </div>
  );
}

type StreakLabelProps = {
  streak: number;
};

function StreakLabel({ streak }: StreakLabelProps): JSX.Element {
  return (
    <div className={styles.streak}>
      <Icon name="streak" className={styles.streakIcon} />
      {streak}
    </div>
  );
}

type ScoreLabelProps = {
  quiz: Quiz;
};

function ScoreLabel({ quiz }: ScoreLabelProps): JSX.Element {
  const score = getScore(quiz);
  const maxScore = getMaxScore(quiz);
  return (
    <div className={styles.result}>
      <Icon className={styles.resultIcon} name="big-correct-ans" />
      <span>
        {score}/{maxScore}
      </span>
    </div>
  );
}

type ResultGridProps = {
  quiz: Quiz;
};

function ResultGrid({ quiz }: ResultGridProps): JSX.Element {
  return (
    <ul className={styles.resultContainer}>
      {quiz.questions.map((question, index) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`${styles.resultBlock} ${
            question.correctAnswerIndex === question.submittedAnswerIndex
              ? styles.correct
              : styles.incorrect
          }`}
        >
          <span>{index + 1}</span>
          <Icon
            className={styles.correctIcon}
            name={
              question.correctAnswerIndex === question.submittedAnswerIndex
                ? "correct"
                : "incorrect"
            }
          />
        </li>
      ))}
    </ul>
  );
}
