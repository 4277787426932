import { useCallback, useEffect, useState } from "react";
import { TimerBar, useTimer } from "common";
import { AnimatePresence, motion } from "framer-motion";
import { Question } from "./quiz.entity";
import { AnswerList } from "./quiz.answer-list";

import styles from "./quiz.question-prompt.module.scss";

export type QuestionPromptProps = {
  question: Question;
  questionIndex: number;
  onAnswer: (index: number | null) => void;
};

export function QuestionPrompt({
  question,
  questionIndex,
  onAnswer: afterRevealAnswer,
}: QuestionPromptProps): JSX.Element {
  const [answerIndex, setAnswerIndex] = useState<number | null>();
  const hasAnswered = answerIndex !== undefined;

  const leftAnswerTimeMs = useTimer(
    {
      totalMs: ANSWER_TIME_MS,
      updateIntervalMs: 10,
    },
    [questionIndex]
  );
  const isAnswerTimeUp = leftAnswerTimeMs === 0;
  const isRevealingAnswer = hasAnswered || isAnswerTimeUp;

  const onUpdateRevealAnswerTimer = useCallback(
    (leftMs: number) => {
      const isTimeUp = leftMs === 0;
      if (isTimeUp) {
        if (hasAnswered) {
          afterRevealAnswer(answerIndex);
        } else {
          afterRevealAnswer(null);
        }
      }
    },
    [afterRevealAnswer, answerIndex, hasAnswered]
  );

  const leftRevealAnswerTimeMs = useTimer(
    {
      totalMs: REVEAL_ANSWER_TIME_MS,
      updateIntervalMs: 10,
      isPaused: !isRevealingAnswer,
      onUpdate: onUpdateRevealAnswerTimer,
    },
    [questionIndex]
  );

  useEffect(() => setAnswerIndex(undefined), [questionIndex]);

  const timerBar = isRevealingAnswer ? (
    <TimerBar
      className={styles.timerBar}
      totalMs={REVEAL_ANSWER_TIME_MS}
      leftMs={leftRevealAnswerTimeMs}
    />
  ) : (
    <TimerBar
      className={styles.timerBar}
      totalMs={ANSWER_TIME_MS}
      leftMs={leftAnswerTimeMs}
    />
  );

  const questionPromptVariants = {
    enter: {
      x: "500px",
      opacity: 0,
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: {
      x: "-500px",
      opacity: 0,
    },
  };

  return (
    <div className={styles.parentContainer}>
      <AnimatePresence initial>
        <motion.div
          className={styles.container}
          key={questionIndex}
          variants={questionPromptVariants}
          transition={{
            x: { duration: 0.8, type: "tween", easings: "easeInOut" },
            opacity: { duration: 0.2 },
          }}
          initial="enter"
          animate="center"
          exit="exit"
        >
          {timerBar}
          <div className={styles.questionContainer}>
            <QuestionHeader
              questionIndex={questionIndex}
              questionText={question.questionText}
            />
            <AnswerList
              question={question}
              onAnswer={setAnswerIndex}
              isRevealingAnswer={isRevealingAnswer}
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

type QuestionHeaderProps = {
  questionIndex: number;
  questionText: string;
};

function QuestionHeader({
  questionIndex,
  questionText,
}: QuestionHeaderProps): JSX.Element {
  const questionNumber = questionIndex + 1;
  return (
    <h1 className={styles.questionText}>
      <span className={styles.questionNo}>{questionNumber}</span>
      {questionText}
    </h1>
  );
}

const ANSWER_TIME_MS = 20000;
const REVEAL_ANSWER_TIME_MS = 5000;
