import svgSprite from "./icons/sprite/stack/sprite.svg";

interface IconProps {
  name: string;
  size?: number | string;
  className?: string;
}

export function Icon({ name, size, className = "" }: IconProps) {
  return (
    <svg className={className} width={size} viewBox="0 0 500 500">
      <use href={`${svgSprite}#${name}`} />
    </svg>
  );
}
