import { logger } from "logging";
import { Buffer } from "buffer";

type QuestionsApiResponse = {
  questionText: string;
  answerTexts: string[];
  correctAnswerIndex: number;
}[];

export async function fetchQuestionsFromWorkerApi() {
  logger.info("Fetching questions from worker API");
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/questions`);
  const body = await response.text();
  const decodedBody = Buffer.from(body, "base64").toString();
  return JSON.parse(decodedBody) as QuestionsApiResponse;
}
