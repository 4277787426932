import { Button } from "common";
import { motion } from "framer-motion";

import { getAnswerLabel } from "./quiz.utils";
import styles from "./quiz.answer-list-item.module.scss";

export type AnswerListItemProps = {
  text: string;
  index: number;
  state: AnswerListItemState;
  onSelect: () => void;
  onConfirm: () => void;
};

export type AnswerListItemState =
  | "initial"
  | "selected"
  | "correct"
  | "incorrect";

export function AnswerListItem({
  text,
  index,
  state,
  onConfirm,
  onSelect,
}: AnswerListItemProps): JSX.Element {
  const label = getAnswerLabel(index);
  const confirmButton = state === "selected" && (
    <Button onClick={onConfirm}>確認答案</Button>
  );

  const variants = {
    enter: {
      scale: 1.1,
      opacity: 0,
    },
    center: {
      scale: 1,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className={styles.container}
      onClick={onSelect}
      onKeyDown={onSelect}
      data-state={state}
      role="button"
      tabIndex={0}
      variants={variants}
    >
      <p className={styles.answerText}>
        <span className={styles.questionIndex}>{label}</span>
        <span>{text}</span>
      </p>
      {confirmButton}
    </motion.div>
  );
}
