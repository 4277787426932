import "./index.css";
import { setupApp } from "app";
import { setupLogging } from "logging";
import { setupMonitoring } from "monitoring";
import { setupServiceWorker } from "service-worker";

setupLogging();
setupMonitoring();
setupServiceWorker();
setupApp();
