import { motion } from "framer-motion";
import clsx from "clsx";
import styles from "./common.styled-button.module.scss";

export interface StyledButtonProps {
  children: JSX.Element | JSX.Element[] | string | string[];
  className?: string;
  tabIndex?: number;
  onClick?: React.MouseEventHandler;
  align?: "left" | "right";
  underLineColor?: string;
}

export function StyledButton({
  children,
  className,
  tabIndex = -1,
  onClick = () => {},
  align = "left",
  underLineColor = "var(--text-color)",
}: StyledButtonProps): JSX.Element {
  const buttonVariants = {
    initial: {
      scale: 1,
    },
    tap: {
      scale: 0.95,
    },
  };

  const underlineVariants = {
    initial: {
      height: "0.2em",
      y: "1.2em",
    },
    hover: {
      height: "1.4em",
      y: "-0.2em",
    },
  };

  const textVariants = {
    initial: {
      color: "#343434", // Using css variable will not work
    },
    hover: {
      color: "#ffffff",
    },
  };

  const getTextPadding = () => {
    if (align === "left") {
      return styles.leftText;
    }
    return styles.rightText;
  };

  return (
    <motion.button
      role="button"
      tabIndex={tabIndex}
      className={clsx(className, styles.styledBtn)}
      onClick={onClick}
      initial="initial"
      whileHover="hover"
      whileFocus="hover"
      whileTap="tap"
      variants={buttonVariants}
    >
      <motion.span
        className={clsx(styles.text, getTextPadding())}
        variants={textVariants}
      >
        {children}
      </motion.span>
      <motion.div
        aria-hidden
        className={styles.underline}
        variants={underlineVariants}
        style={{ backgroundColor: underLineColor }}
      />
    </motion.button>
  );
}
