/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */

import { ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import styles from "./common.button.module.scss";

export function Button({
  className,
  ...restProps
}: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button {...restProps} className={clsx(className, styles.container)} />
  );
}
