import { createRoot } from "react-dom/client";
import { logger } from "logging";
import { App } from "./app";

export function setupApp() {
  const container = document.getElementById("root");
  if (!container) {
    logger.error("Cannot find container for React app");
    return;
  }
  const root = createRoot(container);
  root.render(<App />);
}
