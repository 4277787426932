import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { AnswerListItem, AnswerListItemState } from "./quiz.answer-list-item";
import { Question } from "./quiz.entity";
import styles from "./quiz.answer-list.module.scss";

export type AnswerListProps = {
  question: Question;
  isRevealingAnswer?: boolean;
  onAnswer: (index: number) => void;
};

export function AnswerList({
  question,
  isRevealingAnswer,
  onAnswer,
}: AnswerListProps): JSX.Element {
  const [state, setState] = useState<AnswerListState>({ type: "initial" });

  useEffect(() => setState({ type: "initial" }), [question]);

  const getOnSelect = (answerIndex: number) => () =>
    setState((oldState) => {
      if (isRevealingAnswer || oldState.type === "confirmed") {
        return oldState;
      }
      return { type: "selected", answerIndex };
    });

  const onConfirm = () => {
    if (isRevealingAnswer || state.type !== "selected") {
      return;
    }
    setState({ type: "confirmed", answerIndex: state.answerIndex });
    onAnswer(state.answerIndex);
  };

  const getAnswerListItemState = (answerIndex: number): AnswerListItemState => {
    const isSelected =
      state.type === "selected" && state.answerIndex === answerIndex;
    const isConfirmed =
      state.type === "confirmed" && state.answerIndex === answerIndex;
    const isCorrect = question.correctAnswerIndex === answerIndex;

    if (isRevealingAnswer) {
      if (isCorrect) {
        return "correct";
      }
      if (isConfirmed) {
        return "incorrect";
      }
      return "initial";
    }

    return isSelected ? "selected" : "initial";
  };

  const answerListItems = question.answerTexts.map((answerText, index) => (
    <AnswerListItem
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      state={getAnswerListItemState(index)}
      text={answerText}
      index={index}
      onSelect={getOnSelect(index)}
      onConfirm={onConfirm}
    />
  ));

  const variants = {
    center: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };

  return (
    <motion.div className={styles.container} variants={variants}>
      {answerListItems}
    </motion.div>
  );
}

type AnswerListState =
  | { type: "initial" }
  | { type: "selected" | "confirmed"; answerIndex: number };
