import { StrictMode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LandingPage, RulesPage } from "landing";
import { QuizPage } from "quiz";
import styles from "./app.module.scss";

export function App() {
  return (
    <div className={styles.rootContainer}>
      <StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="rules" element={<RulesPage />} />
            <Route path="/game" element={<QuizPage />} />
          </Routes>
        </BrowserRouter>
      </StrictMode>
    </div>
  );
}
