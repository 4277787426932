import { cloneDeep } from "./common.utils";
import { UnexpectedError } from "./common.errors";

export function formatDate(date: Date): string {
  const dayOfMonth = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${dayOfMonth}/${month}/${year}`;
}

export function getDayOfWeek(date: Date): string {
  switch (date.getDay()) {
    case 0:
      return "星期日";
    case 1:
      return "星期一";
    case 2:
      return "星期二";
    case 3:
      return "星期三";
    case 4:
      return "星期四";
    case 5:
      return "星期五";
    case 6:
      return "星期六";
    default:
      throw new UnexpectedError();
  }
}

export function getPreviousHkMidnight(date: Date): Date {
  const dateClone = cloneDeep(date);
  const hkUTCOffset = 8;
  if (dateClone.getUTCHours() >= 24 - hkUTCOffset) {
    dateClone.setUTCDate(dateClone.getUTCDate() + 1);
  }
  dateClone.setUTCHours(-hkUTCOffset);
  dateClone.setUTCMinutes(0);
  dateClone.setUTCSeconds(0);
  dateClone.setUTCMilliseconds(0);
  return dateClone;
}

export function getYesterday(date: Date): Date {
  const numMsPerDay = 24 * 60 * 60 * 1000;
  return new Date(date.getTime() - numMsPerDay);
}

export function isDateEqual(dateA: Date, dateB: Date) {
  return dateA.getTime() === dateB.getTime();
}
