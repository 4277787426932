import { motion } from "framer-motion";
import styles from "./common.app-bar.module.scss";
import { Icon } from "./common.icon";
import logoIcon from "./logo.svg";

interface AppBarProps {
  bigLogo?: boolean;
}

export function AppBar({ bigLogo }: AppBarProps): JSX.Element {
  return (
    <header className={styles.container}>
      <div className={bigLogo ? styles.bigLogoContainer : styles.logoContainer}>
        <motion.img
          layoutId="logoContainer"
          transition={{ ease: "easeIn", duration: 0.7 }}
          className={styles.logo}
          src={logoIcon}
          alt="settings"
        />
      </div>
      <div className={styles.headerBtn}>
        <Icon name="settings" size="1.3rem" />
      </div>
    </header>
  );
}
