import { logger } from "logging";
import { fetchQuestionsFromWorkerApi } from "worker-api";
import { Quiz } from "./quiz.entity";

export async function createQuiz(): Promise<Quiz> {
  logger.info("Creating quiz");
  const now = new Date();
  const questionsApiResponse = await fetchQuestionsFromWorkerApi();
  return {
    createdAt: now,
    questions: questionsApiResponse,
    currentQuestionIndex: 0,
  };
}
